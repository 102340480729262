import React from "react"
import { content } from "content/about"
import { Banner, Main, CoreValues, HeroAbout, OurTeam, Metadata } from "components"

const About = () => {
	return (
		<>
			<Main>
				<Metadata
					pageTitle="About Us"
					pageDescription="Our vision - to provide world class software development services with an obsessive focus on user experience."
				/>
				<HeroAbout />
				<Banner type={"light"} icon={content.banner.icon} title={content.banner.title} text={content.banner.text} />
				<CoreValues />
				<OurTeam />
			</Main>
		</>
	)
}

export default About
